import React, { useState } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import CurriculumStore from "../../../../stores/CurriculumStore";
import { Spinner, Button, PieTimer } from "../../../-common";
import "./ConfirmDeleteTrackModal.scss";

function ConfirmDeleteTrackModal({ curriculumId, courseId, album, track, close }) {
  const { title: albumTitle } = album || {};
  const { title, order } = track || {};

  const navigate = useNavigate();
  const [deleteEnabled, setDeleteEnabled] = useState(false);
  const enableDelete = () => setDeleteEnabled(true);

  const [deleting, setDeleting] = useState(false);

  const deleteTrack = async () => {
    setDeleting(true);
    await CurriculumStore.deleteTrack(album?.id, track?.id);
    setDeleting(false);
    navigate(`/curriculum/${curriculumId}/courses/${courseId}/albums/${album?.id}?tab=tracks`);
    close();
  };

  const cancelButtonStyles = deleting ? { opacity: 0.1, pointerEvents: "none" } : {};

  const actionButtonStyles = deleting ? { opacity: 0.3, pointerEvents: "none" } : {};
  const actionButtonContent = deleting ? <Spinner /> : "Delete Track";
  const actionButton = deleteEnabled ? (
    <Button type="negative" action={deleteTrack} style={actionButtonStyles}>
      {actionButtonContent}
    </Button>
  ) : (
    <div className="timer-container">
      <PieTimer color="var(--red)" width={24} height={24} duration={3000} onComplete={enableDelete} />
    </div>
  );

  return (
    <div className="confirm-delete-track-modal">
      <div className="content">
        <div className="title">
          Are you sure you want to delete {title} (track #{order}) from {albumTitle}?
        </div>
      </div>
      <div className="bottom-buttons">
        <Button action={close} style={cancelButtonStyles}>
          Cancel
        </Button>
        <div className="left-buttons">{actionButton}</div>
      </div>
    </div>
  );
}

export default observer(ConfirmDeleteTrackModal);
