import React, { useState } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import numeral from "numeral";
import { FiSearch, FiUser } from "react-icons/fi";
import { ENV } from "../../../config";
import { Input, Select, Spinner } from "../../-common";
import useData from "./useData";
import "./SupportFeesTab.scss";

const sortOptions = [
  "Name - A to Z",
  "Name - Z to A",
  "Total Enrollments - Highest to Lowest",
  "Total Enrollments - Lowest to Highest"
];
const sortRows = sortOption => (a, b) => {
  const aTotal = (a?.SB || 0) + (a?.LPM || 0) + (a?.Presto || 0);
  const bTotal = (b?.SB || 0) + (b?.LPM || 0) + (b?.Presto || 0);
  if (sortOption === "Name - A to Z") return a?.name > b?.name ? 1 : -1;
  if (sortOption === "Name - Z to A") return a?.name > b?.name ? -1 : 1;
  if (sortOption === "Total Enrollments - Highest to Lowest") return aTotal > bTotal ? -1 : 1;
  if (sortOption === "Total Enrollments - Lowest to Highest") return aTotal > bTotal ? 1 : -1;
  return 0;
};

const filterOptions = ["All", "Has Sound Beginnings Families", "Has Let's Play Music Students", "Has Presto Students"];
const filterRows = filterOption => row => {
  if (filterOption === "Has Sound Beginnings Families") return !!row?.SB;
  if (filterOption === "Has Let's Play Music Students") return !!row?.LPM;
  if (filterOption === "Has Presto Students") return !!row?.Presto;
  return true;
};

function CurriculumCell({ totals }) {
  const totalTotal = Object.values(totals || {})?.reduce((acc, next) => acc + next, 0);
  const totalLine =
    totalTotal > 0 && Object.keys(totals)?.length > 1 ? (
      <React.Fragment key="total">
        <div className="flexer" />
        <div className="total-divider" />
        <div className="course-breakdown-line">Total: {numeral(totalTotal).format("0,0")}</div>
      </React.Fragment>
    ) : null;
  return Object.entries(totals || {})
    ?.sort((a, b) => (a?.[0] < b?.[0] ? -1 : 1))
    ?.map(([courseName, total]) => {
      return (
        <div className="course-breakdown-line" key={courseName}>
          {courseName}: {numeral(total).format("0,0")}
        </div>
      );
    })
    ?.concat(totalLine)
    ?.filter(Boolean);
}

function TeacherFeeLine({ teacherId, name, SB, LPM, Presto }) {
  const navigate = useNavigate();
  const profilePicture = `https://${ENV}-lpm-assets.b-cdn.net/profiles/${teacherId}`;
  const [profilePictureError, setProfilePictureError] = useState(false);
  const markProfilePictureError = () => setProfilePictureError(true);

  const goToTeacherPage = () => navigate(`/people/${teacherId}?tab=classes`);

  const profilePictureComponent = profilePictureError ? (
    <div className="profile-picture placeholder">
      <FiUser size={36} />
    </div>
  ) : (
    <img className="profile-picture" src={profilePicture} alt="Teacher profile" onError={markProfilePictureError} />
  );

  return (
    <tr className="support-fee-row" onClick={goToTeacherPage}>
      <td>
        <div className="cell-contents flex-center justify-center profile">
          <div className="profile-container">
            {profilePictureComponent}
            <div>
              <div>{name?.toProperCase() || name}</div>
            </div>
          </div>
        </div>
      </td>
      <td>
        <div className="cell-contents flex-center justify-center course-breakdown">
          <CurriculumCell totals={SB} />
        </div>
      </td>
      <td>
        <div className="cell-contents flex-center justify-center course-breakdown">
          <CurriculumCell totals={LPM} />
        </div>
      </td>
      <td>
        <div className="cell-contents flex-center justify-center course-breakdown">
          <CurriculumCell totals={Presto} />
        </div>
      </td>
    </tr>
  );
}

function SupportFeesTab() {
  const { loading, data } = useData();

  const [selectedSort, setSelectedSort] = useState(sortOptions[0]);
  const [selectedFilter, setSelectedFilter] = useState(filterOptions[0]);

  const [searchText, setSearchText] = useState("");
  const updateSearchText = ({ target }) => setSearchText(target.value);

  if (loading) {
    return (
      <div className="analytics-support-fees-tab support-fees-loading">
        <Spinner alt />
      </div>
    );
  }

  const textToMatch = searchText?.toLowerCase();
  const supportFeeRows = data
    ?.filter(({ name }) => (searchText ? name?.toLowerCase()?.includes(textToMatch) : true))
    ?.filter(filterRows(selectedFilter))
    ?.sort(sortRows(selectedSort))
    ?.map(feeLine => <TeacherFeeLine {...feeLine} key={feeLine?.teacherId} />);
  const supportFeesTable = data?.length ? (
    <div className="table-wrapper support-fees-table">
      <table>
        <thead>
          <tr>
            <th>Teacher</th>
            <th className="center">Sound Beginnings Families</th>
            <th className="center">Let's Play Music Students</th>
            <th className="center">Presto Students</th>
          </tr>
        </thead>
        <tbody>{supportFeeRows}</tbody>
      </table>
    </div>
  ) : (
    <div className="table-zero-state">
      <div className="table-zero-state-message">(no support fees)</div>
    </div>
  );
  const content = data?.length ? (
    <>
      <div className="sort-search-filter-row">
        <div className="sort-rows">
          <span className="sort-rows-label">Sort:</span>
          <Select options={sortOptions} value={selectedSort} onChange={setSelectedSort} />
        </div>
        <div className="filter-rows">
          <span className="filter-rows-label">Filter:</span>
          <Select options={filterOptions} value={selectedFilter} onChange={setSelectedFilter} />
        </div>
        <div className="search-teachers">
          <Input icon={FiSearch} placeholder="Search teachers..." value={searchText} onChange={updateSearchText} />
        </div>
      </div>
      {supportFeesTable}
    </>
  ) : (
    <div className="table-zero-state">
      <div className="table-zero-state-message">No teachers with support fees yet!</div>
    </div>
  );

  return (
    <div className="analytics-support-fees-tab">
      <div className="table-container">{content}</div>
    </div>
  );
}

export default observer(SupportFeesTab);
