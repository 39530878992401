import { useState, useEffect } from "react";
import { toJS } from "mobx";
import { useNavigate } from "react-router-dom";
import ShippingStore from "../stores/ShippingStore";

export default function useShipment(shipmentId) {
  const navigate = useNavigate();
  const { shipmentDetails } = ShippingStore;
  const [loading, setLoading] = useState(true);
  const [shipment, setShipment] = useState();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const matchingShipment = shipmentDetails?.[shipmentId];
    if (matchingShipment) {
      setShipment(toJS(matchingShipment));
      setLoading(false);
    } else {
      if (shipmentId) {
        setShipment();
        (async () => {
          setLoading(true);
          const shipments = await ShippingStore.fetchShipmentDetails(shipmentId);
          if (!shipments) navigate("/shipments");
          setLoading(false);
        })();
      }
    }
  }, [shipmentDetails, shipmentId, navigate]);
  return { shipment: shipment || {}, loading };
}
