import { observer } from "mobx-react";
import { useState } from "react";
import AudioPlayer from "react-audio-player";
import { FiChevronRight } from "react-icons/fi";
import { Link, useParams } from "react-router-dom";
import { Spinner } from "../../-common";
import useAlbum from "../../../hooks/useAlbum";
import useCourse from "../../../hooks/useCourse";
import useCurriculum from "../../../hooks/useCurriculum";
import useModal from "../../../hooks/useModal";
import { formatMilliseconds } from "../../../utils";
import ConfirmDeleteTrackModalBody from "./ConfirmDeleteTrackModal";
import "./TrackDetails.scss";
import UpdateDetailsModal from "./UpdateDetailsModal";
import UpdateRecordingModal from "./UpdateRecordingModal/UpdateRecordingModal";

const ZeroState = ({ curriculum, course, album }) => {
  const { title: curriculumTitle, id: curriculumId } = curriculum || {};
  const { name: courseName, id: courseId } = course || {};
  const { title: albumTitle, id: albumId } = album || {};
  const curriculumLink =
    curriculumTitle && curriculumId ? (
      <>
        <FiChevronRight className="arrow-icon" />
        <Link to={`/curriculum/${curriculumId}`}>{curriculumTitle}</Link>
      </>
    ) : null;
  const courseLink =
    curriculumLink && courseName && courseId ? (
      <>
        <FiChevronRight className="arrow-icon" />
        <Link to={`/curriculum/${curriculumId}/courses/${courseId}`}>{courseName}</Link>
      </>
    ) : null;
  const albumLink =
    curriculumLink && courseLink && albumTitle && albumId ? (
      <>
        <FiChevronRight className="arrow-icon" />
        <Link to={`/curriculum/${curriculumId}/courses/${courseId}/albums/${albumId}`}>{albumTitle}</Link>
      </>
    ) : null;
  return (
    <div className="album-details">
      <div className="title-row">
        <div className="title">
          <Link to="/curriculum">Curriculum</Link>
          {curriculumLink}
          {courseLink}
          {albumLink}
        </div>
      </div>
      <div className="loading-container">
        <Spinner alt />
      </div>
    </div>
  );
};

function TrackDetails() {
  const { curriculumId, courseId, albumId, trackId } = useParams();
  const { loading: loadingCurriculum, curriculum } = useCurriculum(curriculumId);
  const { loading: loadingCourse, course } = useCourse(courseId, { curriculumId });
  const { loading: loadingAlbum, album } = useAlbum(albumId, { curriculumId, courseId });
  const track = album?.tracks?.find(t => t?.id === trackId);

  const [updateDetailsModalOpen, setUpdateDetailsModalOpen] = useState(false);
  const openUpdateDetailsModal = () => setUpdateDetailsModalOpen(true);
  const closeUpdateDetailsModal = () => setUpdateDetailsModalOpen(false);

  const [updateRecordingModalOpen, setUpdateRecordingModalOpen] = useState(false);
  const openUpdateRecordingModal = () => setUpdateRecordingModalOpen(true);
  const closeUpdateRecordingModal = () => setUpdateRecordingModalOpen(false);

  const { Modal: ConfirmDeleteTrackModal, openModal: openConfirmDeleteTrackModal } = useModal(
    ConfirmDeleteTrackModalBody,
    { curriculumId, courseId, album, track },
    { portalElementId: "confirm-delete-track-modal" }
  );

  const loading = loadingCurriculum || loadingCourse || loadingAlbum;
  if (loading) return <ZeroState {...{ curriculum, course, album }} />;

  const { title: curriculumTitle } = curriculum || {};
  const { name: courseName } = course || {};
  const { title: albumTitle, artwork } = album || {};
  const { title, duration, principalPurposes, secondaryPurposes } = track || {};
  const principalPurposeEntries = principalPurposes?.map(pp => (
    <li className="learning-purpose" key={pp}>
      {pp}
    </li>
  ));
  const secondaryPurposeEntries = secondaryPurposes?.map(sp => (
    <li className="learning-purpose" key={sp}>
      {sp}
    </li>
  ));

  return (
    <div className="track-details">
      <div className="title-row">
        <div className="title">
          <Link to="/curriculum">Curriculum</Link> <FiChevronRight className="arrow-icon" />
          <Link to={`/curriculum/${curriculumId}`}>{curriculumTitle}</Link> <FiChevronRight className="arrow-icon" />
          <Link to={`/curriculum/${curriculumId}/courses/${courseId}`}>{courseName}</Link>{" "}
          <FiChevronRight className="arrow-icon" />
          <Link to={`/curriculum/${curriculumId}/courses/${courseId}/albums/${albumId}?tab=tracks`}>
            {albumTitle}
          </Link>{" "}
          <FiChevronRight className="arrow-icon" />
          <span>{title}</span>
        </div>
      </div>
      <div className="track-details-body">
        <div className="info-col">
          <div className="info-section-header">Audio Recording</div>
          <div className="audio-player-wrapper">
            <AudioPlayer src={track?.url} controls className="audio-player" />
          </div>
          <div className="info-section-header">Album Artwork</div>
          <img className="artwork" src={artwork} alt={`Artwork for ${albumTitle} album`} />
        </div>
        <div className="info-col">
          <div className="info-section-header">Actions</div>
          <div className="info-row">
            <div className="info-action" onClick={openUpdateDetailsModal}>
              <span>Edit Details</span>
            </div>
          </div>
          <div className="info-row">
            <div className="info-action" onClick={openUpdateRecordingModal}>
              <span>Update Recording</span>
            </div>
          </div>
          <div className="info-row">
            <div className="info-action" onClick={openConfirmDeleteTrackModal}>
              <span>Delete Track</span>
            </div>
          </div>
          <div className="info-section-header">Details</div>
          <div className="info-row">
            <div className="info-title">Title</div>
            <div className="info-value">{title}</div>
          </div>
          <div className="info-row">
            <div className="info-title">Album</div>
            <div className="info-value">{albumTitle}</div>
          </div>
          <div className="info-row">
            <div className="info-title">Duration</div>
            <div className="info-value">{formatMilliseconds(duration)}</div>
          </div>
          <div className="info-row multi">
            <div className="info-title">Principal Learning Purposes</div>
            <div className={`info-value learning-purposes${principalPurposes?.length ? "" : " empty"}`}>
              {principalPurposeEntries || "(none listed)"}
            </div>
          </div>
          <div className="info-row multi">
            <div className="info-title">Secondary Learning Purposes</div>
            <div className={`info-value learning-purposes${secondaryPurposes?.length ? "" : " empty"}`}>
              {secondaryPurposeEntries || "(none listed)"}
            </div>
          </div>
        </div>
        <UpdateDetailsModal {...{ isOpen: updateDetailsModalOpen, close: closeUpdateDetailsModal, album, track }} />
        <UpdateRecordingModal
          {...{ isOpen: updateRecordingModalOpen, close: closeUpdateRecordingModal, album, track }}
        />
        <ConfirmDeleteTrackModal />
      </div>
    </div>
  );
}

export default observer(TrackDetails);
