import React from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../-common";
import "./AlbumsTab.scss";

const AlbumRow = ({ curriculumId, courseId, id: albumId, title, artwork, stats }) => {
  const navigate = useNavigate();
  const { totalTracks } = stats || {};
  const goToAlbumDetails = () => navigate(`/curriculum/${curriculumId}/courses/${courseId}/albums/${albumId}`);

  const albumArtwork = <img className="album-artwork" src={artwork} alt={`Artwork for ${title} album`} />;

  return (
    <tr>
      <td>
        <div className="cell-contents artwork">
          <div>{albumArtwork}</div>
        </div>
      </td>
      <td>
        <div className="cell-contents">{title}</div>
      </td>
      <td>
        <div className="cell-contents">
          <div>
            {totalTracks} track{totalTracks !== 1 ? "s" : ""}
          </div>
        </div>
      </td>
      <td>
        <div className="cell-contents">
          <div className="action" onClick={goToAlbumDetails}>
            View Details
          </div>
        </div>
      </td>
    </tr>
  );
};

const AlbumsTab = ({ curriculum, course, albums, openAddAlbumModal }) => {
  const { id: curriculumId } = curriculum || {};
  const { id: courseId } = course || {};

  const albumRows = albums?.map(album => <AlbumRow {...{ ...album, curriculumId, courseId }} key={album?.id} />);

  const content = albumRows?.length ? (
    <div className="table-wrapper albums-table">
      <table>
        <thead>
          <tr>
            <th>Cover Art</th>
            <th>Title</th>
            <th>Stats</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>{albumRows}</tbody>
      </table>
    </div>
  ) : (
    <div className="table-zero-state">
      <div className="table-zero-state-message">No albums for this course yet!</div>
      <Button action={openAddAlbumModal} type="primary">
        Add Album
      </Button>
    </div>
  );

  return <div className="albums-tab">{content}</div>;
};

export default observer(AlbumsTab);
