import React, { useState } from "react";
import { createPortal } from "react-dom";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import usePortal from "../../../hooks/usePortal";
import CurriculumStore from "../../../stores/CurriculumStore";
import { FullScreenModal, Spinner, Input, Button } from "../../-common";
import "./AddCurriculumModal.scss";

function AddCurriculumModal({ close }) {
  const navigate = useNavigate();
  const [adding, setAdding] = useState(false);

  const [title, setTitle] = useState("");
  const updateTitle = ({ target }) => setTitle(target?.value);

  const addEnabled = !!title;
  const addCurriculum = async () => {
    if (addEnabled) {
      setAdding(true);
      const newCurriculum = await CurriculumStore.addCurriculum(title);
      setAdding(false);
      if (newCurriculum?.id) {
        close();
        navigate(`/curriculum/${newCurriculum.id}`);
      }
    }
  };

  const cancelButtonStyles = adding ? { opacity: 0.1, pointerEvents: "none" } : {};

  const actionButtonStyles = addEnabled
    ? adding
      ? { opacity: 0.3, pointerEvents: "none" }
      : {}
    : { opacity: 0.3, pointerEvents: "none" };
  const actionButtonContent = adding ? <Spinner /> : "Add Curriculum";

  return (
    <FullScreenModal isOpen={true} close={close}>
      <div className="add-curriculum-modal">
        <div className="content">
          <div className="title">Add New Curriculum</div>
          <div className="section-container">
            <div className="section-title">Title</div>
            <Input placeholder="Title for new curriculum" value={title} onChange={updateTitle} />
          </div>
        </div>
        <div className="bottom-buttons">
          <Button action={close} style={cancelButtonStyles}>
            Cancel
          </Button>
          <div className="left-buttons">
            <Button type="primary" action={addCurriculum} style={actionButtonStyles}>
              {actionButtonContent}
            </Button>
          </div>
        </div>
      </div>
    </FullScreenModal>
  );
}

const ObservingModal = observer(AddCurriculumModal);

const AddCurriculumModalContainer = ({ isOpen, ...props }) => {
  const portalTarget = usePortal("add-curriculum-modal-container");
  const contents = isOpen ? <ObservingModal {...props} /> : null;
  return createPortal(contents, portalTarget);
};

export default AddCurriculumModalContainer;
