import React, { useState } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { getDataURLFromFile } from "../../../../utils";
import CurriculumStore from "../../../../stores/CurriculumStore";
import { Spinner, Input, Button, ImageFileSelect } from "../../../-common";
import "./AddAlbumModal.scss";

function AddAlbumModal({ curriculum, course, close }) {
  const { id: curriculumId } = curriculum || {};
  const { id: courseId } = course || {};

  const navigate = useNavigate();

  const [adding, setAdding] = useState(false);

  const [title, setTitle] = useState("");
  const updateTitle = ({ target }) => setTitle(target?.value);

  const [file, setFile] = useState();
  const [filePreviewURL, setFilePreviewURL] = useState();
  const updateFile = async ({ target }) => {
    const selectedFile = target?.files?.[0];
    if (selectedFile) {
      const previewURL = await getDataURLFromFile(selectedFile);
      setFilePreviewURL(previewURL);
      setFile(selectedFile);
    }
  };

  const addEnabled = !!title;
  const addCourse = async () => {
    if (addEnabled) {
      setAdding(true);
      const newAlbum = await CurriculumStore.addAlbum({ curriculumId, courseId, title, artworkFile: file });
      setAdding(false);
      if (newAlbum?.id) {
        close();
        navigate(`/curriculum/${curriculum.id}/courses/${course.id}/albums/${newAlbum.id}`);
      }
    }
  };

  const cancelButtonStyles = adding ? { opacity: 0.1, pointerEvents: "none" } : {};

  const actionButtonStyles = addEnabled
    ? adding
      ? { opacity: 0.3, pointerEvents: "none" }
      : {}
    : { opacity: 0.3, pointerEvents: "none" };
  const actionButtonContent = adding ? <Spinner /> : "Add Album";

  return (
    <div className="add-album-modal">
      <div className="content">
        <div className="title">Add New Album</div>
        <div className="section-container title-section">
          <div className="section-title">Title</div>
          <Input placeholder="Title for new album" value={title} onChange={updateTitle} />
        </div>
        <div className="section-container">
          <div className="section-title">Artwork</div>
          <div className="artwork-select-container">
            <ImageFileSelect {...{ filePreviewURL, updateFile }} />
          </div>
        </div>
      </div>
      <div className="bottom-buttons">
        <Button action={close} style={cancelButtonStyles}>
          Cancel
        </Button>
        <div className="left-buttons">
          <Button type="primary" action={addCourse} style={actionButtonStyles}>
            {actionButtonContent}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default observer(AddAlbumModal);
