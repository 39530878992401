import React, { useState } from "react";
import { observer } from "mobx-react";
import { FiLock } from "react-icons/fi";
import PeopleStore from "../../../../../stores/PeopleStore";
import { Button, Input, Spinner } from "../../../../-common";
import "./ChangeInfusionsoftIdModal.scss";

function ChangeInfusionsoftIdModal({ user, close }) {
  const { firstName } = user || {};
  const [newInfusionsoftId, setNewInfusionsoftId] = useState("");
  const updateNewInfusionsoftId = ({ target }) => setNewInfusionsoftId(target.value);

  const [loading, setLoading] = useState(false);

  const changeInfusionsoftIdEnabled = newInfusionsoftId?.length >= 1;
  const changeInfusionsoftId = async () => {
    if (changeInfusionsoftIdEnabled) {
      setLoading(true);
      await PeopleStore?.changeInfusionsoftIdForUser(user, newInfusionsoftId);
      setLoading(false);
      close();
    }
  };

  const actionButtonStyles = loading ? { opacity: 0.3, pointerEvents: "none" } : {};
  const actionButtonContent = loading ? <Spinner /> : "Submit";

  return (
    <div className="change-infusionsoft-id-modal">
      <div className="content">
        <div className="title">Change {firstName}'s InfusionSoft ID</div>
        <div className="message">
          This will change their InfusionSoft ID in the system immediately. You can always change it back, though.
        </div>
        <div className="input-wrapper">
          <Input
            icon={FiLock}
            type="text"
            placeholder="New InfusionSoft ID"
            value={newInfusionsoftId}
            onChange={updateNewInfusionsoftId}
            pattern="[0-9]*"
            inputMode="numeric"
          />
        </div>
      </div>
      <div className="bottom-buttons">
        <div className="left-buttons">
          <Button type="secondary" action={close}>
            Cancel
          </Button>
        </div>
        <div className="right-buttons">
          <Button
            type="primary"
            className="send-request-button"
            action={changeInfusionsoftId}
            disabled={!changeInfusionsoftIdEnabled}
            style={actionButtonStyles}
          >
            {actionButtonContent}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default observer(ChangeInfusionsoftIdModal);
