import React from "react";
import { observer } from "mobx-react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useTabs from "../../hooks/useTabs";
import EnrollmentsTab from "./EnrollmentsTab";
import SupportFeesTab from "./SupportFeesTab";
import ReportTab from "./ReportTab";
import "./Analytics.scss";

const sections = [
  { title: "Enrollments", component: EnrollmentsTab },
  { title: "Support Fees", component: SupportFeesTab },
  { title: "Reports", component: ReportTab }
];

const Tab = ({ index, title, select, activeTabIndex }) => (
  <div className={`tab${index === activeTabIndex ? " active" : ""}`} onClick={select}>
    {title}
  </div>
);

function Analytics() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { activeTabIndex, tabKeys } = useTabs(sections, searchParams.get("tab"));
  
  const selectTab = index => () => navigate(`/analytics?tab=${tabKeys[index]}`);

  const tabs = sections?.map(({ title }, index) => (
    <Tab {...{ index, title, select: selectTab(index), activeTabIndex }} key={title} />
  ));
  const ActiveSection = sections?.[activeTabIndex]?.component || (() => null);

  const tabBarStyles = { gridTemplateColumns: `repeat(${sections.length}, 1fr)` };

  return (
    <div className="analytics">
      <div className="title-row">
        <div className="title">Reports & Analytics</div>
      </div>
      <div className="tabs-container">
        <div className="tab-bar" style={tabBarStyles}>
          {tabs}
        </div>
        <div className="active-tab-section">
          <ActiveSection />
        </div>
      </div>
    </div>
  );
}

export default observer(Analytics);
