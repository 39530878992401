import React from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import chunk from "lodash.chunk";
import { MAX_ALBUMS_IN_STACKS, ALBUM_ARTWORK_SIZE, ALBUM_ARTWORK_SPACING } from "../../../constants";
import "./CurriculumRow.scss";

const CurriculumRow = observer(({ cellWidth, cellHeight, id: curriculumId, title, albums, stats }) => {
  const { courses: totalCourses = 0, albums: totalAlbums = 0, tracks: totalTracks = 0 } = stats || {};
  const navigate = useNavigate();
  const goToCurriculumDetails = () => navigate(`/curriculum/${curriculumId}`);

  const albumsInStacks = Math.min(totalAlbums, MAX_ALBUMS_IN_STACKS);
  const artworkURLs = albums?.map(({ title, artwork }) => ({ title, artwork }));
  const artworkChunks = chunk(artworkURLs, MAX_ALBUMS_IN_STACKS);
  const artworkStacks = artworkChunks
    .map((ac, i) => {
      const size = ALBUM_ARTWORK_SIZE - (albumsInStacks - 1) * ALBUM_ARTWORK_SPACING;
      const albumCoverItems = ac?.map(({ title, artwork }, j) => (
        <img
          className="album-artwork"
          src={artwork}
          style={{ height: size, width: size, top: ALBUM_ARTWORK_SPACING * j, left: ALBUM_ARTWORK_SPACING * j }}
          alt={`Artwork for ${title} album`}
          key={title}
        />
      ));
      return (
        <div
          className="album-artwork-stack-container"
          style={{
            top: (ALBUM_ARTWORK_SPACING / 2) * i,
            left: 2 * i * ALBUM_ARTWORK_SPACING
          }}
          key={`artwork-stack-${i}`}
        >
          <div className="album-artwork-stacks">{albumCoverItems}</div>
        </div>
      );
    })
    .reverse();

  const artworkContents = artworkStacks?.length ? artworkStacks : <div className="empty-artwork">No Album Artwork</div>;

  return (
    <tr>
      <td>
        <div className="cell-contents artwork" style={{ width: cellWidth, height: cellHeight }}>
          {artworkContents}
        </div>
      </td>
      <td>
        <div className="cell-contents">{title}</div>
      </td>
      <td>
        <div className="cell-contents">
          <div>
            {totalCourses} course{totalCourses !== 1 ? "s" : ""}
          </div>
          <div>
            {totalAlbums} album{totalAlbums !== 1 ? "s" : ""}
          </div>
          <div>
            {totalTracks} track{totalTracks !== 1 ? "s" : ""}
          </div>
        </div>
      </td>
      <td>
        <div className="cell-contents">
          <div className="action" onClick={goToCurriculumDetails}>
            View Details
          </div>
        </div>
      </td>
    </tr>
  );
});

export default CurriculumRow;
