import React, { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import { observer } from "mobx-react";
import { Link, useNavigate } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";
import CurriculumStore from "../../../stores/CurriculumStore";
import MaterialsStore from "../../../stores/MaterialsStore";
import PeopleStore from "../../../stores/PeopleStore";
import { MultiSelect, ParentSearchInput, TeacherSearchInput, Select, ClassOption } from "../../-common";
import useCardValid from "./useCardValid";
import StudentBundleLine from "./StudentBundleLine";
import ProductItem from "./ProductItem";
import CheckoutButton from "./CheckoutButton";
import PaymentInfoSection, { NEW_CARD_OPTION_LABEL } from "./PaymentInfoSection";
import "./AddEnrollment.scss";
import RegistrationsStore from "../../../stores/RegistrationsStore";

const getChildOptionLabel = ({ value }) => `${value?.firstName} ${value?.lastName}`;
const getClassOptionLabel = ({ value }) => `${value.name} (${value.sessionTime})`;

const sortClassOptions = (a, b) => {
  const aCurriculumOrder = CurriculumStore?.curriculaByCourseId?.[a?.courseId]?.order;
  const bCurriculumOrder = CurriculumStore?.curriculaByCourseId?.[b?.courseId]?.order;
  if (aCurriculumOrder !== bCurriculumOrder) {
    return aCurriculumOrder > bCurriculumOrder ? 1 : -1;
  }

  const aCourseOrder = CurriculumStore?.coursesById?.[a?.courseId]?.order;
  const bCourseOrder = CurriculumStore?.coursesById?.[b?.courseId]?.order;
  if (aCourseOrder !== bCourseOrder) {
    return aCourseOrder > bCourseOrder ? 1 : -1;
  }

  if (a?.dayOfWeek !== b?.dayOfWeek) {
    return a?.dayOfWeek > b?.dayOfWeek ? 1 : -1;
  }

  return a?.name > b?.name ? 1 : -1;
};

const AddEnrollment = () => {
  const navigate = useNavigate();
  const [selectedParent, setSelectedParent] = useState();
  const selectedParentId = selectedParent?.id;
  const [selectedChildren, setSelectedChildren] = useState();
  useEffect(() => {
    setSelectedChildren();
  }, [selectedParentId]);

  const [selectedTeacher, setSelectedTeacher] = useState();
  const selectedTeacherId = selectedTeacher?.id;
  const [selectedClass, setSelectedClass] = useState();
  const selectedCourseId = selectedClass?.courseId;
  useEffect(() => {
    setSelectedClass();
  }, [selectedTeacherId]);

  const [studentBundles, setStudentBundles] = useState({});
  const [extras, setExtras] = useState({});
  useEffect(() => {
    setStudentBundles({});
    setExtras({});
  }, [selectedParentId, selectedCourseId]);

  const selectedParentInfusionsoftId = selectedParent?.infusionsoftId;
  const { rawCardsForParents, cardsForParents } = PeopleStore || {};
  const rawCards = rawCardsForParents?.[selectedParentId];
  const cards = cardsForParents?.[selectedParentId];
  useEffect(() => {
    if (selectedParentId && selectedParentInfusionsoftId && !rawCards) {
      PeopleStore?.fetchCardsForParent(selectedParentId, selectedParentInfusionsoftId);
    }
  }, [selectedParentId, selectedParentInfusionsoftId, rawCards]);

  const [selectedCard, setSelectedCard] = useState();
  const updateSelectedCard = selectedCardLabel => {
    if (selectedCardLabel === NEW_CARD_OPTION_LABEL) {
      setSelectedCard(NEW_CARD_OPTION_LABEL);
      setEnteringNewCard(true);
    } else {
      setSelectedCard(cards?.find(c => c?.label === selectedCardLabel));
    }
  };
  const [enteringNewCard, setEnteringNewCard] = useState(false);
  const [newCardDetails, setNewCardDetails] = useState({});
  const cardValid = useCardValid({ cardDetails: newCardDetails, setCardDetails: setNewCardDetails });
  const [processing, setProcessing] = useState(false);
  const [checkoutError, setCheckoutError] = useState();

  const bundleProducts = Object.values(studentBundles)
    ?.map(b => b?.productList)
    ?.flat()
    ?.filter(pid => !MaterialsStore?.shippingProductIds?.includes(pid));
  const extraProducts = Object.entries(extras)?.filter(
    ([pid, quantity]) => !MaterialsStore?.shippingProductIds?.includes(pid) && quantity > 0
  );
  const hasProductsToShip = bundleProducts?.length > 0 || extraProducts?.length > 0;
  const checkoutEnabled =
    !!selectedTeacher &&
    !!selectedClass &&
    !!selectedParent &&
    !!selectedChildren?.length &&
    (!hasProductsToShip || (selectedCard === NEW_CARD_OPTION_LABEL ? cardValid : !!selectedCard));

  const materials = MaterialsStore?.allProductsByCourseId?.[selectedClass?.courseId] || [];
  const bundles = MaterialsStore?.bundlesByCourseId?.[selectedClass?.courseId] || [];

  const selectParent = user => setSelectedParent(user);
  const selectBundle = studentId => bundle => setStudentBundles({ ...studentBundles, [studentId]: bundle });
  const updateExtraQuantity = productId => quantity => setExtras({ ...extras, [productId]: quantity });

  const completePurchase = async () => {
    if (checkoutEnabled) {
      let card = selectedCard;
      setProcessing(true);
      if (selectedCard === NEW_CARD_OPTION_LABEL && cardValid) {
        const email = selectedParent?.email?.[0]?.email;
        card = await PeopleStore?.createCardForParent(
          selectedParentId,
          selectedParentInfusionsoftId,
          email,
          newCardDetails
        );
      }
      const { success, error } = await RegistrationsStore?.addManualRegistration({
        selectedTeacher,
        selectedClass,
        selectedParent,
        selectedChildren,
        studentBundles,
        extras,
        card,
        noProductsToShip: !hasProductsToShip
      });
      if (success) navigate(`/people/${selectedParentId}?tab=enrollments`);
      else setCheckoutError(error);
      setProcessing(false);
    }
  };

  const getNoChildOptionsMessage = () => !selectedParent?.id && "(select a parent to see children)";

  const relevantClasses = (selectedTeacher?.classes || [])
    ?.sort(sortClassOptions)
    ?.map((c, i) => ({ ...c, isFirst: i === 0 }));

  const bundlesTotal = Object.values(studentBundles)
    ?.map(b => b?.price)
    ?.reduce((acc, next) => acc + next, 0);
  const extrasTotal = Object.keys(extras)
    ?.map(productId => MaterialsStore?.productsById?.[productId]?.price)
    ?.reduce((acc, next) => acc + next, 0);
  const shippingCost = MaterialsStore?.shippingItemsByCourseId?.[selectedClass?.courseId]?.price || 0;
  const total = bundlesTotal + extrasTotal + shippingCost;

  const actionButtonStyles = enteringNewCard ? null : { marginTop: 48 };
  const actionButtonDisabled = !checkoutEnabled || processing;
  const actionButton = (
    <CheckoutButton
      style={actionButtonStyles}
      total={total}
      action={completePurchase}
      disabled={actionButtonDisabled}
      processing={processing}
      hasProductsToShip={hasProductsToShip}
    />
  );

  const paymentInfoProps = {
    cards,
    selectedCard,
    updateSelectedCard,
    enteringNewCard,
    setEnteringNewCard,
    newCardDetails,
    setNewCardDetails,
    actionButton,
    checkoutError
  };
  const paymentInfoSection = <PaymentInfoSection {...{ ...paymentInfoProps, hasProductsToShip }} />;

  const studentBundleLines = selectedChildren?.map((s, i) => (
    <StudentBundleLine
      student={s}
      selectedBundle={studentBundles?.[s?.id]}
      bundles={bundles}
      selectBundleForStudent={selectBundle(s?.id)}
      key={s?.id}
    />
  ));

  const productItems = materials?.map(p => (
    <ProductItem product={p} quantity={extras?.[p?.id]} updateQuantity={updateExtraQuantity(p?.id)} key={p?.id} />
  ));

  const showMaterialsSection = selectedTeacher && selectedClass?.id && selectedParent && selectedChildren?.length;
  const selectMaterialsContents = showMaterialsSection ? (
    <div>
      {studentBundleLines}
      <div className="section-header">Extras</div>
      {productItems}
    </div>
  ) : (
    <div className="materials-zero-state">(select teacher, class, parent, and children to continue)</div>
  );

  const shippingDate = selectedClass?.id ? (
    <div className="shipment-date">Shipment Date: {format(parseISO(selectedClass?.shippingDate), "MMM do, yyyy")}</div>
  ) : null;

  return (
    <div className="add-enrollment">
      <div className="title-row">
        <div className="title">
          <Link to="/enrollment" className="title">
            Enrollment
          </Link>
          <FiChevronRight className="arrow-icon" />
          <span>Manual Enrollment</span>
        </div>
      </div>
      <div className="content">
        <div className="col">
          <div className="section">
            <div className="section-header">Teacher</div>
            <TeacherSearchInput value={selectedTeacher} onChange={setSelectedTeacher} />
          </div>
          <div className="section">
            <div className="section-header">Class</div>
            <Select
              options={relevantClasses}
              value={selectedClass}
              onChange={setSelectedClass}
              components={{ Option: ClassOption }}
              formatOptionLabel={getClassOptionLabel}
              maxMenuHeight={220}
            />
            {shippingDate}
          </div>
          <div className="section">
            <div className="section-header">Parent</div>
            <ParentSearchInput onChange={selectParent} />
          </div>
          <div className="section">
            <div className="section-header">Children</div>
            <MultiSelect
              value={selectedChildren}
              onChange={setSelectedChildren}
              options={selectedParent?.children || []}
              noOptionsMessage={getNoChildOptionsMessage}
              formatOptionLabel={getChildOptionLabel}
            />
          </div>
          <div className="section">
            <div className="section-header">Payment Information</div>
            {paymentInfoSection}
          </div>
        </div>
        <div className="col">
          <div className="section">
            <div className="section-header">Materials</div>
            <div className="left-col">{selectMaterialsContents}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(AddEnrollment);
