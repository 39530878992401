import React, { useState } from "react";
import { observer } from "mobx-react";
import { parseISO, format } from "date-fns";
import ShippingStore from "../../../stores/ShippingStore";
import { Button, DatePicker, Spinner } from "..";
import "./ChangeShipByDateModal.scss";

function ChangeShipByDateModal({ 
  teacher, 
  classItem, 
  oldShipByDate, 
  shipmentId,
  onSuccessfulChange = () => null, 
  close 
}) {
  const { id: teacherId, firstName, lastName } = teacher || {};
  const resolvedOldShipByDate = classItem?.shippingDate || oldShipByDate;
  const [newShipByDate, setNewShipByDate] = useState();
  const [loading, setLoading] = useState(false);

  const changeShipByDateEnabled = newShipByDate && 
    (!resolvedOldShipByDate || format(newShipByDate, "yyyy-MM-dd") !== resolvedOldShipByDate);

  const changeShipByDate = async () => {
    if (changeShipByDateEnabled) {
      setLoading(true);
      const newShipByDateString = format(newShipByDate, "yyyy-MM-dd");
      
      let success = false;
      
      // Use the appropriate function based on available data
      if (shipmentId) {
        // If we have a shipmentId, use changeShipDateForShipmentId
        success = await ShippingStore.changeShipDateForShipmentId(shipmentId, newShipByDateString);
      } else if (classItem?.id && teacherId) {
        // If we have classId and teacherId, use changeShipDateForClass (if it exists)
        if (ShippingStore.changeShipDateForClass) {
          success = await ShippingStore.changeShipDateForClass(teacherId, classItem.id, newShipByDateString);
        } else {
          console.error("ShippingStore.changeShipDateForClass is not defined");
          success = false;
        }
      } else {
        console.error("Missing required data: need either shipmentId or both teacherId and classId");
        success = false;
      }
      
      if (success) onSuccessfulChange();
      setLoading(false);
      close();
    }
  };

  // Safely format the old ship by date
  const oldShipByDateLabel = resolvedOldShipByDate ? 
    (() => {
      try {
        return format(parseISO(resolvedOldShipByDate), "MMM do, yyyy");
      } catch (error) {
        console.error("Error formatting date:", error);
        return resolvedOldShipByDate || 'Invalid date';
      }
    })() : 
    'No date set';

  const actionButtonStyles = loading ? { opacity: 0.3, pointerEvents: "none" } : {};
  const actionButtonContent = loading ? <Spinner /> : "Submit";

  return (
    <div className="change-ship-by-date-modal">
      <div className="content">
        <div className="title">
          Change {firstName} {lastName}'s Ship By Date
        </div>
        <div className="message">
          This will change the requested shipping date for any outstanding materials {firstName} has requested on{" "}
          {oldShipByDateLabel}.
        </div>
        <div className="input-wrapper">
          <DatePicker
            placeholder="New Ship By Date"
            value={newShipByDate}
            onChange={setNewShipByDate}
            // minDate={today}
          />
        </div>
      </div>
      <div className="bottom-buttons">
        <div className="left-buttons">
          <Button type="secondary" action={close}>
            Cancel
          </Button>
        </div>
        <div className="right-buttons">
          <Button
            type="primary"
            className="send-request-button"
            action={changeShipByDate}
            disabled={!changeShipByDateEnabled}
            style={actionButtonStyles}
          >
            {actionButtonContent}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default observer(ChangeShipByDateModal);
