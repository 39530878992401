// eslint-disable-next-line
String.prototype.toProperCase = function () {
  return this.replace(/\w\S*/g, txt => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const uniqueArray = arr => {
  return [...new Set(arr)];
};

export const divideArray = (arr, n) => {
  const minItemsPerSubarray = Math.floor(arr.length / n);
  const totalSubarraysWithExtras = arr.length % n;
  let groups = [];
  for (let i = 0; i < n; i++) {
    const startIndex = i * minItemsPerSubarray + (i < totalSubarraysWithExtras ? i : totalSubarraysWithExtras);
    const endIndex = (i + 1) * minItemsPerSubarray + (i < totalSubarraysWithExtras ? i + 1 : totalSubarraysWithExtras);
    groups.push(arr.slice(startIndex, endIndex));
  }
  return groups;
};

export { default as downloadURLContents } from "./downloadURLContents";
export { default as evaluateUnstableData } from "./evaluateUnstableData";
export { default as formatMilliseconds } from "./formatMilliseconds";
export { default as formatSeconds } from "./formatSeconds";
export { default as generateShipmentId } from "./generateShipmentId";
export { default as getDataURLFromFile } from "./getDataURLFromFile";
export { default as getDurationFromAudioFile } from "./getDurationFromAudioFile";
export { default as request } from './request';
export * as chartData from "./chartData";
