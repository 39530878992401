import axios from 'axios';
import { fetchAuthSession } from '@aws-amplify/auth';
import { API_HOST } from '../config';

// Create an axios instance with default configuration
const api = axios.create({
  baseURL: API_HOST,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Add request interceptor to add auth token
api.interceptors.request.use(
  async (config) => {
    try {
      const session = await fetchAuthSession();
      if (session?.tokens?.idToken) {
        config.headers.Authorization = `Bearer ${session.tokens.idToken.toString()}`;
      }
    } catch (error) {
      console.error('Error getting auth token:', error);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add response interceptor for error handling
api.interceptors.response.use(
  (response) => response.data,
  (error) => {
    console.error('API request failed:', error.message);
    // Return empty object instead of rejecting to prevent app crashes
    return Promise.resolve({});
  }
);

const request = {
  get: async (path, options = {}) => {
    try {
      console.log(`Making GET request to ${API_HOST}${path}`);
      return await api.get(path, options);
    } catch (error) {
      console.error(`GET request failed for ${path}:`, error);
      return {};
    }
  },
  post: async (path, dataOrOptions = {}) => {
    try {
      console.log(`Making POST request to ${API_HOST}${path}`);
      // Handle both formats: either {body: data} or just data
      const data = dataOrOptions.body || dataOrOptions;
      const options = dataOrOptions.body ? (({ body, ...rest }) => rest)(dataOrOptions) : {};
      return await api.post(path, data, options);
    } catch (error) {
      console.error(`POST request failed for ${path}:`, error);
      return {};
    }
  },
  put: async (path, dataOrOptions = {}) => {
    try {
      console.log(`Making PUT request to ${API_HOST}${path}`);
      // Handle both formats: either {body: data} or just data
      const data = dataOrOptions.body || dataOrOptions;
      const options = dataOrOptions.body ? (({ body, ...rest }) => rest)(dataOrOptions) : {};
      return await api.put(path, data, options);
    } catch (error) {
      console.error(`PUT request failed for ${path}:`, error);
      return {};
    }
  },
  delete: async (path, options = {}) => {
    try {
      console.log(`Making DELETE request to ${API_HOST}${path}`);
      return await api.delete(path, options);
    } catch (error) {
      console.error(`DELETE request failed for ${path}:`, error);
      return {};
    }
  }
};

export default request;