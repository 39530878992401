import { makeAutoObservable } from "mobx";
import { 
  signIn, 
  signOut, 
  fetchAuthSession,
  getCurrentUser,
  fetchUserAttributes
} from '@aws-amplify/auth';
import { v4 as uuidV4 } from "uuid";
import { request } from "../utils";

class AuthStore {
  authenticated = false;
  authDetermined = false;
  loading = false;
  error = null;
  user = null;
  groups = [];
  localCredentials = {};
  
  constructor() {
    makeAutoObservable(this);
    // Delay the auth check to ensure Amplify is initialized
    setTimeout(() => this.checkAuth(), 200);
  }
  
  async checkAuth() {
    try {
      // Check if user is authenticated
      const currentUser = await getCurrentUser();
      if (currentUser) {
        // Also fetch the session to get tokens
        const session = await fetchAuthSession();
        console.log('Auth session:', session);
        
        this.authenticated = true;
        
        // Get user attributes if needed
        const attributes = await fetchUserAttributes();
        this.user = { attributes };
        console.log('User attributes:', attributes);
      }
    } catch (error) {
      console.log('Not authenticated', error);
      this.authenticated = false;
    } finally {
      this.authDetermined = true;
    }
  }
  
  async signIn(username, password) {
    return this.login(username, password);
  }
  
  async login(username, password) {
    this.loading = true;
    this.error = null;
    
    try {
      // First check if already signed in
      let isAlreadySignedIn = false;
      try {
        await getCurrentUser();
        isAlreadySignedIn = true;
      } catch (e) {
        // Not signed in, which is what we want
      }
      
      // If already signed in, sign out first
      if (isAlreadySignedIn) {
        try {
          console.log("User already signed in, signing out first...");
          await signOut({ global: true });
          // Wait a moment for the sign-out to complete
          await new Promise(resolve => setTimeout(resolve, 500));
        } catch (e) {
          console.error("Error during sign-out:", e);
        }
      }
      
      // Now try to sign in
      console.log("Attempting to sign in with username:", username);
      const { isSignedIn, nextStep } = await signIn({
        username,
        password,
      });
      
      if (isSignedIn) {
        this.authenticated = true;
        await this.checkAuth();
        return { success: true };
      } else if (nextStep) {
        // Handle MFA or other auth challenges if needed
        return { success: false, nextStep };
      }
    } catch (error) {
      console.error('Login error:', error);
      
      // If we still get "already authenticated" error, try a different approach
      if (error.name === 'UserAlreadyAuthenticatedException') {
        try {
          console.log("Still getting already authenticated error, trying alternative approach");
          // Force the user to be considered authenticated
          this.authenticated = true;
          await this.checkAuth();
          return { success: true };
        } catch (e) {
          console.error("Alternative approach failed:", e);
        }
      }
      
      this.error = error.message || 'Failed to sign in';
      return { success: false, error: this.error };
    } finally {
      this.loading = false;
    }
  }
  
  async logout() {
    this.loading = true;
    
    try {
      await signOut({ global: true });
      this.authenticated = false;
      this.user = null;
    } catch (error) {
      console.error('Logout error:', error);
    } finally {
      this.loading = false;
    }
  }

  get sub() {
    return this.user?.attributes?.sub;
  }

  async signUp({ firstName, lastName, email, phone, address1, address2, city, state, zip, password }) {
    this.loading = true;

    let success, error, lpmId;
    try {
      const username = uuidV4();
      await signIn(username, password, { attributes: { email, phone_number: phone } });
      const { id } = await request.post("/accounts", {
        body: {
          username,
          firstName,
          lastName,
          email,
          phone,
          address1,
          address2,
          city,
          state,
          zip
        }
      });
      success = true;
      lpmId = id;
      this.localCredentials = { lpmId, username, email, password };
    } catch (err) {
      console.warn(err);
      success = false;
      if (err.message === "PreSignUp failed with error A user with the same email address exists.") {
        error = "User account with the same email already exists. Please log in or reset password instead.";
      } else {
        error = err.message;
      }
    }

    this.loading = false;
    return { success, error, lpmId };
  }

  async resendVerificationCode() {
    let success, error;

    this.loading = true;
    try {
      await signIn(this.localCredentials?.username);
      success = true;
    } catch (err) {
      error = err.message;
      success = false;
    }

    this.loading = false;
    return { success, error };
  }

  async verifyAccount(code) {
    this.loading = true;
    try {
      const { username } = this.localCredentials || {};
      await signIn(username, code, { forceAliasCreation: true });
      return { success: true };
    } catch (err) {
      this.loading = false;
      return { success: false, error: err.message };
    }
  }

  async sendResetPasswordCode(email) {
    let success, error;

    this.loading = true;
    try {
      await signIn(email);
      success = true;
    } catch (err) {
      success = false;
      error = err.message;
    }

    this.loading = false;
    return { success, error };
  }

  async resetPassword(username, code, newPassword) {
    this.loading = true;
    let success, error;

    try {
      await signIn(username, code, newPassword);
      success = true;
    } catch (err) {
      success = false;
      error = err.message;
    }

    this.loading = false;
    return { success, error };
  }
}

export default new AuthStore();
