import { useState, useEffect } from "react";
import { toJS } from "mobx";
import { useNavigate } from "react-router-dom";
import PeopleStore from "../stores/PeopleStore";

export default function usePerson(lpmId) {
  const navigate = useNavigate();
  const { people } = PeopleStore;
  const [loading, setLoading] = useState(true);
  const [person, setPerson] = useState();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const matchingPerson = people.find(p => p?.id === lpmId);
    if (matchingPerson) {
      setPerson(toJS(matchingPerson));
      setLoading(false);
    } else {
      if (lpmId) {
        setPerson();
        (async () => {
          setLoading(true);
          const person = await PeopleStore.fetchPersonById(lpmId);
          if (!person) navigate("/people");
          setLoading(false);
        })();
      }
    }
  }, [people, lpmId, navigate]);
  return { person: person || {}, loading };
}
