import React, { useState } from "react";
import { Link } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { FiUser } from "react-icons/fi";
import "./StudentItem.scss";

const StudentItem = ({ student }) => {
  const { id, profilePicture, gender, firstName, lastName, birthday, birthdayText: ageLabel } = student || {};

  const [profilePictureError, setProfilePictureError] = useState(false);
  const markProfilePictureError = () => setProfilePictureError(true);

  const profilePictureComponent = profilePictureError ? (
    <div className={`profile-picture placeholder${gender ? " " + gender : ""}`}>
      <FiUser size={36} />
    </div>
  ) : (
    <img
      className="profile-picture"
      src={profilePicture || ""}
      alt="Student profile"
      onError={markProfilePictureError}
    />
  );

  const birthdayLabel = birthday ? format(parseISO(birthday), "'Birthday on' MMM do") : "-";

  return (
    <div className="student-item flex-center">
      {profilePictureComponent}
      <div>
        <Link className="action" to={`/people/${id}`}>
          {firstName} {lastName}
        </Link>
        <div className="aux">{ageLabel}</div>
        <div className="aux">{birthdayLabel}</div>
      </div>
    </div>
  );
};

export default StudentItem;
