import { format, parseISO } from "date-fns";
import { observer } from "mobx-react";
import { useState } from "react";
import { FiChevronRight } from "react-icons/fi";
import { Link, useParams } from "react-router-dom";
import { ChangeShipByDateModal as ChangeShipByDateModalBody, Spinner } from "../../-common";
import useClassDetails from "../../../hooks/useClassDetails";
import useModal from "../../../hooks/useModal";
import usePerson from "../../../hooks/usePerson";
import ClassesStore from "../../../stores/ClassesStore";
import PeopleStore from "../../../stores/PeopleStore";
import ChangeClassNameModalBody from "./ChangeClassNameModal";
import "./ClassOverview.scss";
import ConfirmDeleteClassModalBody from "./ConfirmDeleteClassModal";
import EditRegistrationDetailsModalBody from "./EditRegistrationDetailsModal";
import EditScheduleDetailsModalBody from "./EditScheduleDetailsModal";
import StudentsTable from "./StudentsTable";

const ZeroState = () => (
  <div className="class-overview">
    <div className="title-row">
      <div className="title">
        <Link to="/people">People</Link>
      </div>
    </div>
    <div className="loading-container">
      <Spinner alt />
    </div>
  </div>
);

const LoadingStudents = () => {
  return (
    <div className="loading-container">
      <Spinner alt />
    </div>
  );
};

const StudioLocationInfo = ({ name, line1, line2, locality: city, region: state, zipCode }) => {
  const addressLine2 = line2 ? (
    <div className="info-row cont" key={`${name}-line2`}>
      <div className="info-title" />
      <div className="info-value">{line2}</div>
    </div>
  ) : null;
  return [
    <div className="info-row" key={`${name}-title`}>
      <div className="info-title">Studio Location</div>
      <div className="info-value">{name}</div>
    </div>,
    <div className="info-row cont" key={`${name}-title`}>
      <div className="info-title" />
      <div className="info-value">{line1}</div>
    </div>,
    addressLine2,
    <div className="info-row cont" key={`${name}-citystatezip`}>
      <div className="info-title" />
      <div className="info-value">
        {city}, {state} {zipCode}
      </div>
    </div>
  ]?.filter(Boolean);
};

function ClassOverview() {
  const { lpmId, classId } = useParams();
  const { loading: loadingPerson } = usePerson(lpmId);
  const person = PeopleStore?.peopleById?.[lpmId] || {};
  const classItem = ClassesStore.classesById?.[classId] || person?.classes?.find(c => c?.id === classId) || {};
  const { loading: loadingStudents } = useClassDetails(classId, lpmId);

  const students = ClassesStore?.studentsForClasses?.[classId] || [];
  const droppedStudents = ClassesStore?.droppedStudentsForClasses?.[classId] || [];

  const [refreshing, setRefreshing] = useState(false);
  const refreshClass = () => {
    setRefreshing(true);
    PeopleStore?.fetchPersonById(lpmId);
    setRefreshing(false);
  };

  const { Modal: ChangeClassNameModal, openModal: openChangeClassNameModal } = useModal(
    ChangeClassNameModalBody,
    { currentClass: classItem },
    { portalElementId: "change-class-name-modal" }
  );

  const { Modal: EditRegistrationDetailsModal, openModal: openEditRegistrationDetailsModal } = useModal(
    EditRegistrationDetailsModalBody,
    { currentClass: classItem },
    { portalElementId: "edit-registration-details-modal" }
  );

  const { Modal: EditScheduleDetailsModal, openModal: openEditScheduleDetailsModal } = useModal(
    EditScheduleDetailsModalBody,
    { currentClass: classItem },
    { portalElementId: "edit-schedule-details-modal" }
  );

  const { Modal: ChangeShipByDateModal, openModal: openChangeShipByDateModal } = useModal(
    ChangeShipByDateModalBody,
    { teacher: person, classItem, onSuccessfulChange: refreshClass },
    { portalElementId: "change-ship-by-date-modal" }
  );

  const { Modal: ConfirmDeleteClassModal, openModal: openConfirmDeleteClassModal } = useModal(
    ConfirmDeleteClassModalBody,
    { class: classItem },
    { portalElementId: "confirm-delete-class-modal" }
  );

  const cleanUpOrderItems = async () => {
    await ClassesStore.cleanUpOrderItemsForClass(lpmId, classId);
  };

  const [downloading, setDownloading] = useState(false);
  const downloadRoster = async () => {
    setDownloading(true);
    await ClassesStore?.downloadRosterForClass(classId);
    setDownloading(false);
  };

  if (loadingPerson || refreshing) return <ZeroState />;

  const { courseId, sessionTime, capacity, stats, period, shippingDate, location } = classItem || {};
  const { studentsEnrolled, parentsEnrolled } = stats || {};

  const personFullName = `${person?.firstName} ${person?.lastName}`;

  const seatsAvailable = capacity - studentsEnrolled;
  const seatsAvailableDetails = `(${studentsEnrolled} / ${capacity} students, ${parentsEnrolled} parents)`;

  const formattedShippingDate =
    shippingDate && shippingDate !== "0000-00-00" ? format(parseISO(shippingDate), "MMM do, yyyy") : "-";

  const deleteClassAction =
    !loadingStudents && students?.length === 0 ? (
      <div className="action-row">
        <div className="action" onClick={openConfirmDeleteClassModal}>
          <span>Delete Class</span>
        </div>
      </div>
    ) : null;

  const downloadButtonContent = downloading ? (
    <div className="action-loading-container">
      <Spinner action />
    </div>
  ) : (
    <span>Download Roster</span>
  );

  const body = false ? (
    <LoadingStudents />
  ) : (
    <>
      <div className="upper">
        <div className="info-section">
          <div className="header">Details</div>
          <div className="info-row">
            <div className="info-title">When</div>
            <div className="info-value">{sessionTime}</div>
          </div>
          <div className="info-row">
            <div className="info-title">Seats Available</div>
            <div className="info-value">
              {seatsAvailable} <span className="seats-available-details">{seatsAvailableDetails}</span>
            </div>
          </div>
          <div className="info-row">
            <div className="info-title">Period</div>
            <div className="info-value">{period}</div>
          </div>
          <div className="info-row">
            <div className="info-title">Requested Shipment Date</div>
            <div className="info-value">{formattedShippingDate}</div>
          </div>
          <StudioLocationInfo {...location} />
        </div>
        <div className="actions-section">
          <div className="header">Actions</div>
          <div className="action-row">
            <div className="action" onClick={openChangeClassNameModal}>
              <span>Change Class Name</span>
            </div>
          </div>
          <div className="action-row">
            <div className="action" onClick={openEditRegistrationDetailsModal}>
              <span>Edit Registration Details</span>
            </div>
          </div>
          <div className="action-row">
            <div className="action" onClick={openEditScheduleDetailsModal}>
              <span>Modify Schedule Details</span>
            </div>
          </div>
          <div className="action-row">
            <div className="action" onClick={openChangeShipByDateModal}>
              <span>Update Shipment Date</span>
            </div>
          </div>
          <div className="action-row">
            <div className="action" onClick={cleanUpOrderItems}>
              <span>Clean Up Order Items For Class</span>
            </div>
          </div>
          <div className="action-row">
            <div className="action" onClick={downloadRoster}>
              {downloadButtonContent}
            </div>
          </div>
          {deleteClassAction}
        </div>
      </div>
      <div className="header">Roster</div>
      <StudentsTable {...{ teacher: person, class: classItem, students, classId, courseId }} />
      <div className="header">Dropped Students</div>
      <StudentsTable
        {...{
          teacher: person,
          class: classItem,
          students: droppedStudents,
          classId,
          courseId,
          forDroppedStudents: true
        }}
      />
    </>
  );

  return (
    <div className="class-overview">
      <div className="title-row">
        <div className="title">
          <Link to="/people">People</Link> <FiChevronRight className="arrow-icon" />
          <Link to={`/people/${lpmId}?tab=classes`}>{personFullName}</Link> <FiChevronRight className="arrow-icon" />
          <span>{classItem?.name}</span>
        </div>
      </div>
      {body}
      <ChangeClassNameModal />
      <EditRegistrationDetailsModal />
      <EditScheduleDetailsModal />
      <ChangeShipByDateModal />
      <ConfirmDeleteClassModal />
    </div>
  );
}

export default observer(ClassOverview);
