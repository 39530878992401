import React from "react";
import { createRoot } from "react-dom/client";
import { configure as configureMobX } from "mobx";
import initialzeAmplify from "./services/AmplifyService";
import App from "./app";
import "./styles/index.scss";

// Update MobX configuration for React 19 compatibility
configureMobX({ 
  enforceActions: "never",
  // Add React 19 specific configuration if needed
});

// Ensure Amplify is properly initialized
initialzeAmplify();

// Wrap the rendering in a try/catch to better identify errors
try {
  const root = createRoot(document.getElementById("root"));
  root.render(<React.StrictMode><App /></React.StrictMode>);
} catch (error) {
  console.error("Error rendering application:", error);
  // Optionally render an error message to the DOM
  document.getElementById("root").innerHTML = 
    '<div style="color: red; padding: 20px;">Application failed to start. See console for details.</div>';
}
