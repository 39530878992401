import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Search from "./Search";
import AddAccount from "./AddAccount";
import PersonOverview from "./PersonOverview";
import ClassOverview from "./ClassOverview";

export default function People() {
  return (
    <Routes>
      <Route path="/" element={<Search />} />
      <Route path="/new" element={<AddAccount />} />
      <Route path="/:lpmId" element={<PersonOverview />} />
      <Route path="/:lpmId/classes/:classId" element={<ClassOverview />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}
