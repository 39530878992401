import React, { useState } from "react";
import { observer } from "mobx-react";
import { Input, Spinner } from "../../../components/-common";
import { Link, useNavigate } from "react-router-dom";
import AuthStore from "../../../stores/AuthStore";
import "./ResetPassword.scss";

function ResetPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const updateEmail = ({ target }) => setEmail(target.value);

  const [codeSendError, setCodeSendError] = useState();
  const [codeSent, setCodeSent] = useState(false);

  const [code, setCode] = useState("");
  const updateCode = ({ target }) => setCode(target.value);

  const [password, setPassword] = useState("");
  const updatePassword = ({ target }) => setPassword(target.value);

  const [confirmPassword, setConfirmPassword] = useState("");
  const updateConfirmPassword = ({ target }) => setConfirmPassword(target.value);

  const sendCodeEnabled = !!email;
  const sendCode = async () => {
    if (sendCodeEnabled) {
      setCodeSent(false);
      setCodeSendError(null);
      const { success, error } = await AuthStore.sendResetPasswordCode(email);
      if (success) {
        setCodeSent(true);
      } else {
        if (error === "Username/client id combination not found.") {
          setCodeSendError("Couldn't find an account associated with that email.");
        } else {
          setCodeSendError(error);
        }
      }
    }
  };

  const resetEnabled = code && code.length === 6 && password && password.length > 5 && password === confirmPassword;

  const [resetError, setResetError] = useState();

  const resetPassword = async () => {
    if (resetEnabled) {
      setResetError(null);
      const { success, error } = await AuthStore.resetPassword(email, code, password);
      if (success) {
        navigate("/resetpassword/success");
        setTimeout(async () => await AuthStore.signIn(email, password), 3000);
      } else {
        setResetError(error);
      }
    }
  };

  const codeButtonContents =
    AuthStore.loading && !codeSent ? <Spinner /> : codeSent ? "RESEND RESET CODE" : "SEND RESET CODE";
  const codeButtonStyles = sendCodeEnabled
    ? AuthStore.loading
      ? { opacity: 0.7, pointerEvents: "none" }
      : {}
    : { opacity: 0.3, pointerEvents: "none" };

  const resetButtonContents = AuthStore.loading && codeSent ? <Spinner /> : "RESET PASSWORD";
  const resetButtonStyles = resetEnabled
    ? AuthStore.loading
      ? { opacity: 0.7, pointerEvents: "none" }
      : {}
    : { opacity: 0.3, pointerEvents: "none" };

  return (
    <div className="reset-password">
      {/* <BackgroundBlobs /> */}
      <div className="auth-content">
        <h3 className="auth-title">Reset Password</h3>
        <div className="auth-square">
          <div className="auth-inputs">
            <Input type="text" placeholder="Email Address" value={email} onChange={updateEmail} error={codeSendError} />
            <button style={codeButtonStyles} className="auth-button" onClick={sendCode}>
              {codeButtonContents}
            </button>
            <Input
              type="password"
              placeholder="New Password"
              value={password}
              onChange={updatePassword}
              disabled={!codeSent}
            />
            <Input
              type="password"
              placeholder="Confirm New Password"
              value={confirmPassword}
              onChange={updateConfirmPassword}
              disabled={!codeSent}
            />
            <Input placeholder="Code" value={code} onChange={updateCode} disabled={!codeSent} error={resetError} />
            <button style={resetButtonStyles} className="auth-button" onClick={resetPassword}>
              {resetButtonContents}
            </button>
            <div className="redirect">
              <Link to="/auth" className="log-in-link">
                <span>Just remembered it?</span>
                <span className="auth-link">Sign In Here</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(ResetPassword);
