import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
// import { Spinner } from "../-common";
import useQueryParams from "../../hooks/useQueryParams";
import SettingsTab from "./SettingsTab";
import BannersTab from "./BannersTab";
import PushNotificationsTab from "./PushNotificationsTab";
import GenericTab from "./GenericTab";
import "./Mobile.scss";

const sections = [
  { title: "Settings", component: SettingsTab },
  { title: "Banners", component: BannersTab },
  { title: "Push Notifications", component: PushNotificationsTab },
  { title: "Tab 4", component: GenericTab(4) },
  { title: "Tab 5", component: GenericTab(5) }
];

const Tab = ({ index, title, select, activeTabIndex }) => (
  <div className={`tab${index === activeTabIndex ? " active" : ""}`} onClick={select}>
    {title}
  </div>
);

function Mobile() {
  const navigate = useNavigate();
  const { tab } = useQueryParams();
  const tabKeys = sections?.map(({ title }) => title?.toLowerCase());

  const initialTabIndex = Math.max(tabKeys?.indexOf(tab), 0);
  const [activeTabIndex, setActiveTabIndex] = useState(initialTabIndex);
  useEffect(() => {
    setActiveTabIndex(initialTabIndex);
  }, [initialTabIndex]);
  useEffect(() => {
    if (activeTabIndex > sections?.length - 1) setActiveTabIndex(initialTabIndex);
  }, [activeTabIndex, initialTabIndex]);

  const selectTab = index => () => navigate(`/mobile?tab=${tabKeys[index]}`);

  // if (false) {
  //   return (
  //     <div className="person-overview">
  //       <div className="title-row">
  //         <div className="title">Mobile</div>
  //       </div>
  //       <div className="loading-container">
  //         <Spinner alt />
  //       </div>
  //     </div>
  //   );
  // }

  const tabs = sections?.map(({ title }, index) => (
    <Tab {...{ index, title, select: selectTab(index), activeTabIndex }} key={title} />
  ));
  const ActiveSection = sections?.[activeTabIndex]?.component || (() => null);

  const tabBarStyles = { gridTemplateColumns: `repeat(${sections.length}, 1fr)` };

  return (
    <div className="mobile">
      <div className="title-row">
        <div className="title">Mobile</div>
      </div>
      <div className="tabs-container">
        <div className="tab-bar" style={tabBarStyles}>
          {tabs}
        </div>
        <div className="active-tab-section">
          <ActiveSection />
        </div>
      </div>
    </div>
  );
}

export default observer(Mobile);
