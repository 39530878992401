import { observer } from "mobx-react";
import { FiX } from "react-icons/fi";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PromoCodes from "src/components/@PromoCodes";
import { Spinner } from "../components/-common";
import Analytics from "../components/@Analytics";
import AuthRoutes from "../components/@Auth";
import Curriculum from "../components/@Curriculum";
import Enrollment from "../components/@Enrollment";
import Mobile from "../components/@Mobile";
import People from "../components/@People";
import Products from "../components/@Products";
import Shipping from "../components/@Shipping";
import Sidebar from "../components/Sidebar";
import AuthStore from "../stores/AuthStore";
import UserStore from "../stores/UserStore";
import "./App.scss";

const ToastClose = ({ closeToast }) => (
  <div className="toast-close">
    <FiX size={20} onClick={closeToast} />
  </div>
);

function Main() {
  return (
    <div className="main-content">
      <Sidebar />
      <Routes>
        <Route path="/people/*" element={<People />} />
        <Route path="/enrollment/*" element={<Enrollment />} />
        <Route path="/curriculum/*" element={<Curriculum />} />
        <Route path="/products/*" element={<Products />} />
        <Route path="/promo/*" element={<PromoCodes />} />
        <Route path="/mobile" element={<Mobile />} />
        <Route path="/shipping/*" element={<Shipping />} />
        <Route path="/analytics" element={<Analytics />} />
        <Route path="*" element={<Navigate to="/people" replace />} />
      </Routes>
    </div>
  );
}

const App = observer(() => {
  if (!AuthStore.authDetermined || (AuthStore.authenticated && !UserStore?.user?.id && UserStore.loading)) {
    return (
      <div className="root loading">
        <Spinner alt={true} />
      </div>
    );
  }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        closeButton={<ToastClose />}
        className="lpm-toast"
      />
      {AuthStore.authenticated ? <Main /> : <AuthRoutes />}
    </>
  );
});

function ProvidersWrappedApp() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}

export default ProvidersWrappedApp;
