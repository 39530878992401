import React, { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { Link, useParams } from "react-router-dom";
import { FiChevronRight, FiBox } from "react-icons/fi";
import { format, parseISO } from "date-fns";
import useModal from "../../../hooks/useModal";
import useShipment from "../../../hooks/useShipment";
import { Spinner } from "../../-common";
import AddShipmentNotesModal from "./AddShipmentNotesModal";
import "./ShipmentOverview.scss";

const ZeroState = () => (
  <>
    <div className="title-row">
      <div className="title">
        <Link to="/people">People</Link>
      </div>
    </div>
    <div className="loading-container">
      <Spinner alt />
    </div>
  </>
);

const OrderItemRow = ({ name, productPicture, quantity, processedQuantity = 0, backorderedQuantity = 0 }) => {
  const [productPictureError, setProfilePictureError] = useState(false);
  const markProfilePictureError = () => setProfilePictureError(true);

  const productPictureComponent = productPictureError ? (
    <div className="product-picture placeholder">
      <FiBox size={36} />
    </div>
  ) : (
    <img className="product-picture" src={productPicture} alt="This product" onError={markProfilePictureError} />
  );

  return (
    <tr>
      <td>
        <div className="cell-contents product">
          {productPictureComponent}
          <div>{name}</div>
        </div>
      </td>
      <td>
        <div className="cell-contents">
          <div className="quantity-container">{quantity}</div>
        </div>
      </td>
      <td>
        <div className="cell-contents quantity-cell">
          <div className="quantity-container">
            <div className="quantity">{processedQuantity || 0}</div>
          </div>
        </div>
      </td>
      {/* <td>
        <div className="cell-contents quantity-cell">
          <div className="quantity-container">
            <div className="quantity">{backorderedQuantity || 0}</div>
          </div>
        </div>
      </td> */}
      {/* <td>
        <div className="cell-contents">
          <div className="action">Mark All as Processed</div>
          <div className="action">Mark All as Backordered</div>
        </div>
      </td> */}
    </tr>
  );
};

const OrderItemsTable = ({ orderItems }) => {
  const orderItemRows = orderItems
    ?.filter(({ name }) => !name?.toLowerCase()?.includes("shipping"))
    ?.filter(({ name }) => !name?.toLowerCase()?.includes("tax"))
    ?.filter(({ name }) => !name?.toLowerCase()?.includes("fee"))
    ?.map(orderItem => <OrderItemRow {...{ ...orderItem }} key={orderItem?.id} />);

  return orderItems?.length ? (
    <div className="table-wrapper order-items-table">
      <table>
        <thead>
          <tr>
            <th>Product Name</th>
            <th className="center">Total Ordered</th>
            <th className="center">Total Processed</th>
            {/* <th className="center">Total Backordered</th> */}
            {/* <th>Actions</th> */}
          </tr>
        </thead>
        <tbody>{orderItemRows}</tbody>
      </table>
    </div>
  ) : (
    <div className="table-zero-state">
      <div className="table-zero-state-message">
        Looks like something's up - no order items to show. Reach out to T&T and we'll get it sorted out.
      </div>
    </div>
  );
};

function ShipmentOverview() {
  const { shipmentId } = useParams();
  const { loading: loadingShipment, shipment } = useShipment(shipmentId);

  const { teacher, requestedShippingDate, items, notes } = shipment || {};
  const { addresses } = teacher || {};

  const _wrapperRef = useRef();
  const { current: wrapperRef } = _wrapperRef || {};
  useEffect(() => {
    if (!!wrapperRef) {
      if (!!notes) wrapperRef?.style?.setProperty("--upper-height", "248px");
      else wrapperRef?.style?.setProperty("--upper-height", "175px");
    }
  }, [wrapperRef, notes]);

  const { Modal: AddNotesModal, openModal: openAddNotesModal } = useModal(
    AddShipmentNotesModal,
    { shipment },
    { portalElementId: "add-shipment-notes-modal" }
  );

  let rawAddress = addresses?.find(a => a?.field === "SHIPPING");
  if (!rawAddress) rawAddress = addresses?.[0];
  const { line1, line2, locality: city, region: state, zipCode } = rawAddress || {};
  const addressLine2 = line2 ? (
    <div className="info-row additional">
      <div className="info-title"></div>
      <div className="info-value">{line2?.toProperCase()}</div>
    </div>
  ) : null;

  const shipDateLabel = requestedShippingDate ? format(parseISO(requestedShippingDate), "MMM do, yyyy") : "";

  const shipmentFullName = `${teacher?.fullName}: ${shipDateLabel}`;

  const notesSection = notes ? (
    <div className="info-row">
      <div className="info-title">Notes</div>
      <div className="info-value">{notes}</div>
    </div>
  ) : null;

  const content =
    loadingShipment || !wrapperRef ? (
      <ZeroState />
    ) : (
      <>
        <div className="title-row">
          <div className="title">
            <Link to="/shipping">Shipping</Link> <FiChevronRight className="arrow-icon" />
            <Link to={`/shipping/${shipmentId}?tab=classes`}>{shipmentFullName}</Link>
          </div>
        </div>
        <div className="upper">
          <div className="info-section">
            <div className="header">Details</div>
            <div className="info-row">
              <div className="info-title">Teacher</div>
              <div className="info-value">{teacher?.fullName}</div>
            </div>
            <div className="info-row">
              <div className="info-title">Address</div>
              <div className="info-value">{line1?.toProperCase()}</div>
            </div>
            {addressLine2}
            <div className="info-row additional">
              <div className="info-title"></div>
              <div className="info-value">
                {city?.toProperCase()}, {state?.toUpperCase()} {zipCode}
              </div>
            </div>
            <div className="info-row">
              <div className="info-title">Req. Ship Date</div>
              <div className="info-value">{shipDateLabel}</div>
            </div>
            {notesSection}
          </div>
          <div className="actions-section">
            <div className="header">Actions</div>
            <div className="action-row">
              <div className="action" onClick={openAddNotesModal}>
                <span>Add/Edit Notes</span>
              </div>
            </div>
          </div>
        </div>
        <div className="header table-header">Order Items</div>
        <OrderItemsTable {...{ orderItems: items }} />
        <AddNotesModal />
      </>
    );

  return (
    <div className="shipment-overview" ref={_wrapperRef}>
      {content}
    </div>
  );
}

export default observer(ShipmentOverview);
