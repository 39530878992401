import { useState, useEffect } from "react";
import { toJS } from "mobx";
import { useNavigate } from "react-router-dom";
import ClassesStore from "../stores/ClassesStore";

export default function useClassDetails(classId, fallbackLPMId) {
  const navigate = useNavigate();
  const { studentsForClasses } = ClassesStore;
  const [loading, setLoading] = useState(true);
  const [students, setStudents] = useState();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const matchingStudentsForClass = studentsForClasses[classId];
    if (matchingStudentsForClass) {
      setStudents(toJS(matchingStudentsForClass));
      setLoading(false);
    } else {
      if (classId) {
        (async () => {
          setLoading(true);
          const classItem = await ClassesStore.fetchStudentsForClassById(classId);
          await ClassesStore.fetchDroppedStudentsForClassById(classId);
          if (!classItem) navigate(`/people/${fallbackLPMId}`);
          setLoading(false);
        })();
      }
    }
  }, [studentsForClasses, classId, fallbackLPMId, navigate]);
  return { loading, students } || {};
}
