import React from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { FiUser } from "react-icons/fi";
import useModal from "../../../hooks/useModal";
import AppStateStore from "../../../stores/AppStateStore";
import PeopleStore from "../../../stores/PeopleStore";
import { PersonSearchInput } from "../../-common";
import SyncMissingLPMIDModalBody from "./SyncMissingLPMIDModal";
import "./Search.scss";

const RecentlyViewedItem = ({ person, goToPersonDetails }) => {
  const { firstName, lastName, email, isParent, isStudent, isTeacher, infusionsoftId, userId: lpmId } = person || {};

  const goToPerson = () => goToPersonDetails(person);

  const teacherChip = isTeacher ? (
    <div className="chip teacher" key="t">
      Teacher
    </div>
  ) : null;
  const parentChip = isParent ? (
    <div className="chip parent" key="p">
      Parent
    </div>
  ) : null;
  const studentChip = isStudent ? (
    <div className="chip student" key="s">
      Student
    </div>
  ) : null;
  const chips = [teacherChip, parentChip, studentChip].filter(Boolean);
  return (
    <div className="recently-viewed-item-wrapper" onClick={goToPerson}>
      <div className="recently-viewed-item-content">
        <div className="profile-image">
          <FiUser color="var(--medium-gray)" size={40} />
        </div>
        <div className="info-container">
          <div className="recently-viewed-item-name">
            {firstName?.toProperCase()} {lastName?.toProperCase()}
          </div>
          <div className="recently-viewed-item-email">{email}</div>
          <div className="recently-viewed-item-id">InfusionSoft ID: {infusionsoftId}</div>
          <div className="recently-viewed-item-id">LPM ID: {lpmId}</div>
          <div className="person-type-chips-container">{chips}</div>
        </div>
      </div>
    </div>
  );
};

function Search() {
  const navigate = useNavigate();
  
  const { Modal: SyncMissingLPMIDModal, openModal: openSyncMissingLPMIDModal } = useModal(
    SyncMissingLPMIDModalBody,
    {},
    { portalElementId: "add-playlist-modal" }
  );

  const goToNewAccountCreation = () => navigate(`/people/new`);

  const goToPersonDetails = person => {
    const { firstName, lastName, userId } = person;
    AppStateStore.setPendingPersonForDetailsScreen({ firstName, lastName, userId });
    navigate(`/people/${userId}`);
  };

  const recentlyViewedItems = PeopleStore?.recentlyViewed?.map(person => (
    <RecentlyViewedItem {...{ person, goToPersonDetails }} key={person?.userId} />
  ));
  const recentlyViewedBody = recentlyViewedItems?.length ? (
    <div className="recently-viewed-items">{recentlyViewedItems}</div>
  ) : (
    <div className="recently-viewed-zero-state-wrapper">
      <div className="recently-viewed-zero-state-text">
        You haven't viewed anyone recently - search above to get started!
      </div>
    </div>
  );

  return (
    <div className="people-search">
      <div className="title-row">
        <div className="title">People</div>
        <div className="search-input-wrapper">
          <PersonSearchInput onSelect={goToPersonDetails} />
        </div>
      </div>
      <div className="upper">
        <div className="info-section"></div>
        <div className="actions-section">
          <div className="header">Actions</div>
          <div className="action-row">
            <div className="action" onClick={goToNewAccountCreation}>
              <span>Add New Account</span>
            </div>
          </div>
          <div className="action-row">
            <div className="action" onClick={openSyncMissingLPMIDModal}>
              <span>Fix Missing Person</span>
            </div>
          </div>
        </div>
      </div>
      <div className="recently-viewed">
        <div className="recently-viewed-header">Recently Viewed</div>
        {recentlyViewedBody}
      </div>
      <SyncMissingLPMIDModal />
    </div>
  );
}

export default observer(Search);
