import {
  fetchAuthSession,
  getCurrentUser,
  signIn,
  signOut
} from '@aws-amplify/auth';
import { cognitoUserPoolsTokenProvider } from '@aws-amplify/auth/cognito';
import { Amplify } from 'aws-amplify';
import { API_HOST, API_NAME, COGNITO_CONFIG } from "../config";

export default function initialzeAmplify() {
  console.log('Configuring Amplify with:', { COGNITO_CONFIG, API_HOST, API_NAME });

  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: COGNITO_CONFIG.userPoolId,
        userPoolClientId: COGNITO_CONFIG.userPoolWebClientId,
        region: COGNITO_CONFIG.region,
        loginWith: {
          username: true,
          email: true,
          phone: false
        }
      }
    },
    API: {
      REST: {
        [API_NAME]: {
          endpoint: API_HOST,
          region: COGNITO_CONFIG.region,
          custom_header: async () => {
            try {
              const session = await fetchAuthSession();
              return {
                Authorization: `Bearer ${session.tokens.idToken.toString()}`,
                'Content-Type': 'application/json'
              };
            } catch (error) {
              console.error('Error getting auth session:', error);
              return {
                'Content-Type': 'application/json'
              };
            }
          }
        }
      }
    }
  });

  // Initialize the token provider
  cognitoUserPoolsTokenProvider.setKeyValueStorage({
    getItem: (key) => localStorage.getItem(key),
    setItem: (key, value) => localStorage.setItem(key, value),
    removeItem: (key) => localStorage.removeItem(key)
  });

  console.log('Amplify configured successfully');
}

// Export the auth methods for use in other files
export { fetchAuthSession, getCurrentUser, signIn, signOut };

