import React from "react";
import { observer } from "mobx-react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { ALBUM_ARTWORK_SIZE, ALBUM_ARTWORK_SPACING } from "../../constants";
import { Spinner } from "../-common";
import RegistrationsStore from "../../stores/RegistrationsStore";
import AddEnrollment from "./AddEnrollment";
import "./Enrollment.scss";

const CourseArtwork = ({ course }) => {
  const { albums } = course || {};

  let artwork = <div className="album-artwork placeholder" />;
  if (albums?.length > 1) {
    const size = ALBUM_ARTWORK_SIZE - (albums?.length - 1) * ALBUM_ARTWORK_SPACING;
    const albumCoverItems = albums?.map(({ title, artwork }, i) => (
      <img
        className="album-artwork"
        src={artwork}
        style={{ height: size, width: size, top: ALBUM_ARTWORK_SPACING * i, left: ALBUM_ARTWORK_SPACING * i }}
        alt={`Artwork for ${title} album`}
        key={i}
      />
    ));
    artwork = <div className="multi-album-artwork-container">{albumCoverItems}</div>;
  } else if (albums?.[0]) {
    const { title, artwork: albumArtwork } = albums[0];
    artwork = <img src={albumArtwork} className="album-artwork" alt={`Artwork for ${title} album`} />;
  }

  return <div className="cell-contents artwork">{artwork}</div>;
};

const EnrollmentRow = ({ registrationDate, students, parent, class: classItem }) => {
  const { id, classId: fallbackId, name, sessionTime, period, course, teacher } = classItem || {};
  const classId = id || fallbackId;

  const registrationDateLabel = format(parseISO(registrationDate), "MMM do ''yy");
  const registrationTimeLabel = format(parseISO(registrationDate), "p");

  const studentLines = students?.map(({ id, firstName, lastName }) => (
    <div className="student-line" key={id}>
      <Link className="action" to={`/people/${id}`}>
        {firstName} {lastName}
      </Link>
    </div>
  ));

  return (
    <tr>
      <td>
        <div className="cell-contents date">
          <div>{registrationDateLabel}</div>
          <div>at {registrationTimeLabel}</div>
        </div>
      </td>
      <td>
        <CourseArtwork course={course} />
      </td>
      <td>
        <div className="cell-contents">
          <Link className="action" to={`/people/${teacher?.id}`}>
            {teacher?.firstName} {teacher?.lastName}
          </Link>
          <div className="aux class-details first">{name}</div>
          <div className="aux class-details">{sessionTime}</div>
          <div className="aux class-details">({period})</div>
        </div>
      </td>
      <td>
        <div className="cell-contents">
          {studentLines}
          <div className="aux registered-by">
            (registered by{" "}
            <Link className="action" to={`/people/${parent?.id}`}>
              {parent?.firstName} {parent?.lastName}
            </Link>
            )
          </div>
        </div>
      </td>
      <td>
        <div className="cell-contents">
          <Link className="action" to={`/people/${teacher?.id}/classes/${classId}`}>
            View Class Details
          </Link>
        </div>
      </td>
    </tr>
  );
};

const Enrollment = observer(() => {
  let { loading, recentRegistrations, registrationsToday } = RegistrationsStore || {};

  if (loading) {
    return (
      <div className="enrollment enrollment-loading">
        <Spinner alt={true} />
      </div>
    );
  }

  const enrollmentRows = recentRegistrations?.map(e => {
    const key = `${e?.parent?.id}-${e?.registrationDate}-${e?.students?.map(s => s?.id)?.join("-")}`;
    return <EnrollmentRow {...e} key={key} />;
  });
  const enrollmentTableContent = recentRegistrations?.length ? (
    <div className="table-wrapper enrollments-table">
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Course</th>
            <th>Class</th>
            <th>Enrollees</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>{enrollmentRows}</tbody>
      </table>
    </div>
  ) : (
    <div className="table-zero-state">
      <div className="table-zero-state-message">No recent enrollments to show</div>
    </div>
  );

  return (
    <div className="enrollment">
      <div className="title-row">
        <div className="title">Enrollment</div>
      </div>
      <div className="upper">
        <div className="info-section">
          <div className="header">Stats</div>
          <div className="info-row">
            <div className="info-title">Enrollments Today</div>
            <div className="info-value">{registrationsToday}</div>
          </div>
        </div>
        <div className="actions-section">
          <div className="header">Actions</div>
          <div className="action-row">
            <div className="action">
              <Link to="/enrollment/new">Manually Enroll Student(s)</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="header">Recent Enrollments</div>
      {enrollmentTableContent}
    </div>
  );
});

const EnrollmentRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Enrollment />} />
      <Route path="/new" element={<AddEnrollment />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default EnrollmentRouter;
