import React, { useState } from "react";
import { observer } from "mobx-react";
import { Routes, Route, Navigate } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import useModal from "../../hooks/useModal";
import PromoCodesStore from "src/stores/PromoCodesStore";
import { Input, Select, PromoCodeRow, Button } from "../-common";
import AddPromoCodesModalBody from "./AddPromoCodesModal";
import "./PromoCodes.scss";

const sortOptions = ["Name - A to Z", "Name - Z to A", "Price - Lowest to Highest", "Price - Highest to Lowest"];

const sortPromoCodes = selectedSort => (a, b) => {
  if (selectedSort === "Name - A to Z") return a?.name > b?.name ? 1 : -1;
  else if (selectedSort === "Name - Z to A") return a?.name > b?.name ? -1 : 1;
  else if (selectedSort === "Price - Lowest to Highest") return a?.price > b?.price ? 1 : -1;
  else if (selectedSort === "Price - Highest to Lowest") return a?.price > b?.price ? -1 : 1;
};

const PromoCodes = observer(() => {
  const { promoCodes } = PromoCodesStore || {};

  const { Modal: AddPromoCodeModal, openModal: openAddPromoCodeModal } = useModal(
    AddPromoCodesModalBody,
    {},
    { portalElementId: "add-promo-codes-modal" }
  );

  const [selectedSort, setSelectedSort] = useState(sortOptions[0]);

  const [searchText, setSearchText] = useState("");
  const updateSearchText = ({ target }) => setSearchText(target.value);

  const totalPromoCodes = promoCodes?.length || 0;

  const applicablePromoCodes = promoCodes
    ?.filter(p => {
      if (!searchText) return true;
      const textToMatch = searchText?.toLowerCase();
      return p?.code?.toLowerCase()?.includes(textToMatch);
    })
    ?.sort(sortPromoCodes(selectedSort));
  const promoCodeRows = applicablePromoCodes?.map(promoCode => <PromoCodeRow {...promoCode} key={promoCode?.id} />);

  const promoCodesTable = applicablePromoCodes?.length ? (
    <div className="table-wrapper promo-codes-table">
      <table>
        <thead>
          <tr>
            <th>Code</th>
            <th>Description</th>
            <th>Status</th>
            <th>Type</th>
            <th>Value</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>{promoCodeRows}</tbody>
      </table>
    </div>
  ) : (
    <div className="table-zero-state">
      <div className="table-zero-state-message">(no promo codes)</div>
    </div>
  );
  const promoCodesTableContent = promoCodes?.length ? (
    <>
      <div className="sort-search-filter-row">
        <div className="sort-promo-codes">
          <span className="sort-promo-codes-label">Sort:</span>
          <Select options={sortOptions} value={selectedSort} onChange={setSelectedSort} />
        </div>
        <div className="search-promo-codes">
          <Input icon={FiSearch} placeholder="Search promo codes..." value={searchText} onChange={updateSearchText} />
        </div>
      </div>
      {promoCodesTable}
    </>
  ) : (
    <div className="table-zero-state">
      <div className="table-zero-state-message">No promo codes yet!</div>
      <Button action={openAddPromoCodeModal} type="primary">
        Add Promo Code
      </Button>
    </div>
  );

  return (
    <div className="promo-codes">
      <div className="title-row">
        <div className="title">Promo Codes</div>
      </div>
      <div className="upper">
        <div className="info-section">
          <div className="header">Stats</div>
          <div className="info-row">
            <div className="info-title">Total Promo Codes</div>
            <div className="info-value">{totalPromoCodes}</div>
          </div>
        </div>
        <div className="actions-section">
          <div className="header">Actions</div>
          <div className="action-row">
            <div className="action" onClick={openAddPromoCodeModal}>
              <span>Add Promo Code</span>
            </div>
          </div>
        </div>
      </div>
      <div className="header">Promo Codes</div>
      {promoCodesTableContent}
      <AddPromoCodeModal />
    </div>
  );
});

export default function PromoCodesNav() {
  return (
    <Routes>
      <Route path="/" element={<PromoCodes />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}
